import { Component } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'spark-starter-angular';

  // This is the main animation asset! Server it via CDN or keep it bundled with your code...it's up to you
  // Either way, just make sure it can be resolved by your site over the web (bundling doesn't work)
  // And make sure it get's gzipped (or equivalent), which will take it from ~370kb down to about 30kb!
  options: AnimationOptions = {
    path: './assets/not_found_parachute_shadow.json',
  };
 
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
